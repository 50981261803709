var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"currency",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.network)+" ")]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])])]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{
            name: 'transactions',
            query: {
              historySearchString: row.address,
            },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(row.address)+" ")])]}},{key:"addressTag",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.tagMessage)+" ")]}},{key:"status",fn:function(ref){
          var row = ref.row;
return [(row.active)?_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$t('Active'))+" ")]):_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Inactive'))+" ")])]}},{key:"req",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }