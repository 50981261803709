//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: { type: Object, default: () => ({}) },
  },

  computed: {
    tradeId() {
      try {
        const { tradeId } = this.data.p2P;
        return tradeId;
      } catch (error) {
        return '';
      }
    },
  },
};
