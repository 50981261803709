//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      hideForP2pay: this.$utils.getAppType() === 'p2pay',
      filters: {
        searchString: '',
        cumulativeCurrencyId: 0,
        currencyTypeList: [],
      },
      constant,
    };
  },

  computed: {
    ...mapState('userBalanceBalances', ['filterLists']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),

    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },


  methods: {
    ...mapActions('userBalanceBalances', ['setFilters']),
  },

};
