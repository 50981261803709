
export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 10,
    p2payVersionRequired: [1],
    header: {
      type: 'text',
      caption: 'Limit',
    },
    cell: {
      type: 'slot',
      name: 'limit',
    },
  },
  {
    width: 10,
    p2payVersionRequired: [1],
    header: {
      type: 'text',
      caption: 'Profit',
    },
    cell: {
      type: 'slot',
      name: 'profit',
    },
  },
  {
    width: 10,
    p2payVersionRequired: [1, 2, 3],
    header: {
      type: 'text',
      caption: 'Trust',
    },
    cell: {
      type: 'slot',
      name: 'trust',
    },
  },
  {
    width: 10,
    p2payVersionRequired: [1, 2, 3],
    header: {
      type: 'text',
      caption: 'Hold',
    },
    cell: {
      type: 'slot',
      name: 'hold',
    },
  },
  {
    width: 10,
    p2payVersionRequired: [1],
    header: {
      type: 'text',
      caption: 'Debt',
    },
    cell: {
      type: 'slot',
      name: 'debt',
    },
  },
];
