//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const';
import TransactionInternalOut from './Extra/TransactionInternalOut.vue';
import TransactionInternalIn from './Extra/TransactionInternalIn.vue';
import Referral from './Extra/Referral.vue';
import P2P from './Extra/P2P.vue';
import TransactionFee from './Extra/TransactionFee.vue';
import Fee from './Extra/Fee.vue';
import P2pFee from './Extra/P2pFee.vue';
import Transaction from './Extra/Transaction.vue';

export default {
  components: {
    TransactionInternalOut,
    TransactionInternalIn,
    Referral,
    P2P,
    TransactionFee,
    Fee,
    P2pFee,
    Transaction,
  },

  props: {
    operation: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      constant,
      ready: false,
      data: {},
      history: [],
      user: {},
      transactionMethod: {},
    };
  },

  computed: {
    TYPE() {
      const { OPERATION_TYPE } = constant.operations;
      return OPERATION_TYPE;
    },
  },

  created() {
    this.getOperation();
  },

  methods: {
    ...mapActions('common', ['setGeneralProgress']),
    ...mapActions('transactionsHistoryOld', {
      getOperationAction: 'getOperation',
      getTransactionStatusHistoryAction: 'getTransactionStatusHistory',
      getTransactionMethodAction: 'getTransactionMethod',
    }),
    ...mapActions('user', {
      getUserAction: 'getUser',
    }),

    async getOperation() {
      const {
        id,
        operationType,
        userId,
      } = this.operation;
      const { TYPE } = this;

      this.ready = false;
      this.setGeneralProgress(true);
      this.data = await this.getOperationAction({ id, operationType });
      if (operationType === TYPE.TRANSACTION) {
        const { transactionMethodId } = this.data.transaction;
        const extra = await Promise.all([
          this.getTransactionStatusHistoryAction({
            transactionId: id,
            limit: 1000,
          }),
          this.getUserAction(userId),
          this.getTransactionMethodAction(transactionMethodId),
        ]);
        const { history } = extra[0];
        const user = extra[1];
        const transactionMethod = extra[2];

        this.history = history;
        this.user = user;
        this.transactionMethod = transactionMethod;
      }
      this.ready = true;
      this.setGeneralProgress(false);
    },
  },
};
