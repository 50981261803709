//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import UserSelect from '~/components/UserSelect.vue';
import constant from '~/const';

export default {
  components: {
    UserSelect,
  },

  props: {
    value: { type: Boolean, default: false },
    routerId: { type: String, default: () => '' },
  },

  data() {
    return {
      constant,
      isOld: !process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old'),
      balance: '',
      amount: null,
      userId: '',
      currencyId: null,
      comment: '',
      details: '',
      errors: {
        amount: undefined,
        currencyId: undefined,
        details: undefined,
        balance: undefined,
      },
    };
  },

  computed: {
    ...mapGetters('currencies', ['getCurrencyById']),
    ...mapState('currencies', { currenciesOld: 'currencies' }),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('user', ['user']),

    getCurrencies() {
      if (this.isOld) {
        return this.currenciesOld;
      }

      switch (this.$utils.getAppVersion()) {
        case 2:
        case 3:
          return this.currencies.filter((e) => e.title === 'USDT');
        case 1:
          return this.currencies.filter((e) => e.title === 'USDT' || e.title === 'WRUB');
        default:
          return this.currencies;
      }
    },

    getBalanceTypes() {
      const res = [];
      switch (this.$utils.getAppVersion()) {
        case 1:
          if (this.currencyId === 'WRUB') {
            res.push(constant.balance.BALANCE_DEPOSIT_TYPE_LIST[constant.balance.BALANCE_DEPOSIT_TYPE.Balance_Hold]);
            res.push(constant.balance.BALANCE_DEPOSIT_TYPE_LIST[constant.balance.BALANCE_DEPOSIT_TYPE.Balance_Debt]);
            if (this.user.roles[0] === 1) res.push(constant.balance.BALANCE_DEPOSIT_TYPE_LIST[constant.balance.BALANCE_DEPOSIT_TYPE.Balance_Profit]);
          }
          if (this.currencyId === 'USDT') res.push(constant.balance.BALANCE_DEPOSIT_TYPE_LIST[constant.balance.BALANCE_DEPOSIT_TYPE.Balance_Trust]);
          return res;
        case 2:
        case 3:
          return constant.balance.BALANCE_DEPOSIT_TYPE_LIST.filter((elem) => elem.caption === 'Trust' || elem.caption === 'Hold');
        default:
          return [];
      }
    },

    isApplyDisabled() {
      const { amount, currencyId, details } = this;
      return !amount || !currencyId || !details;
    },

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    ...mapActions('userBalanceBalances', {
      loadBalance: 'loadData',
      addBalanceAction: 'addBalance',
    }),
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),

    validate(string, field) {
      const validators = string.split(' ');
      let inError = false;
      validators.forEach((validator) => {
        switch (validator) {
          case 'require':
            if (!this[field]) {
              inError = true;
              this.errors[field] = this.$t('This field is required.');
            } else {
              inError = false;
              this.errors[field] = '';
            }
            break;
          case 'size':
            if (this[field].length >= 1024) {
              inError = true;
              this.errors[field] = this.$t('This field can`t be more 1024 characters');
            }
            if (!inError) {
              this.errors[field] = '';
            }
            break;
          default:
            break;
        }
      });
    },

    addBalance() {
      const {
        amount,
        userId,
        currencyId,
        comment,
        details,
        routerId,
        balance,
      } = this;

      if (currencyId && amount && (routerId || userId) && details.length > 0) {
        this.confirmAction({
          title: 'Are you sure want to add balance to user?',
          hasCancelButton: true,
          callback: async () => {
            try {
              this.setGeneralProgress(true);
              if (this.isOld) {
                await this.addBalanceAction({
                  amount: Number(amount),
                  currencyId,
                  comment,
                  details,
                  userId: !routerId ? userId : routerId,
                });
              } else {
                await this.addBalanceAction({
                  userId: !routerId ? userId : routerId,
                  balance,
                  currency: currencyId,
                  amount: Number(amount),
                  comment,
                });
              }
              this.setSuccessNotification('Balance successfully sent');
              this.loadBalance();
              this.setGeneralProgress(false);
              this.closeAddBalanceModal();
            } catch (error) {
              this.closeAddBalanceModal();
              this.setGeneralProgress(false);
              this.setErrorNotification(error.message);
            }
          },
        });
      } else {
        this.setErrorNotification('Required fields are empty!');
      }
    },

    clearErrors() {
      this.errors = {
        amount: undefined,
        currencyId: undefined,
        details: undefined,
        balance: undefined,
      };
    },

    closeAddBalanceModal() {
      this.clearErrors();
      this.amount = null;
      this.currencyId = null;
      this.balance = '';
      this.comment = '';
      this.details = '';
      this.$emit('close');
    },
  },
};
