export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'slot',
      name: 'currency',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 55,
    header: {
      type: 'text',
      caption: 'Address',
    },
    cell: {
      type: 'slot',
      name: 'address',
    },
  },
  // {
  //   width: 15,
  //   header: {
  //     type: 'text',
  //     caption: 'Address tag',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'addressTag',
  //   },
  // },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  // {
  //   width: 15,
  //   header: {
  //     type: 'text',
  //     caption: 'Req',
  //   },
  //   cell: {
  //     type: 'slot',
  //     name: 'req',
  //   },
  // },
];
