//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import UserBalanceBalances from '~/views/UserBalance/UserBalanceBalances/UserBalanceBalances.vue';
import UserBalanceTransactions from '~/views/UserBalance/UserBalanceTransactions/TransactionsHistory.vue';
import UserBalanceTransactionsOld from '~/views/UserBalance/UserBalanceTransactionsOld/TransactionsHistory.vue';
import UserBalanceAddresses from '~/views/UserBalance/UserBalanceAddresses/UserBalanceAddresses.vue';
import UserBalanceAddressesOld from '~/views/UserBalance/UserBalanceAddressesOld/UserBalanceAddresses.vue';
import UserBalanceLimits from '~/views/UserBalance/UserBalanceLimits/UserBalanceLimits.vue';

export default {
  components: {
    UserBalanceBalances,
    UserBalanceTransactions,
    UserBalanceAddresses,
    UserBalanceAddressesOld,
    UserBalanceLimits,
    UserBalanceTransactionsOld,
  },

  async mounted() {
    this.setGeneralLoader(true);
    if (!process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old')) {
      await this.getTransactionsHistoryAction();
      await this.loadAddressesOld();
      await this.loadBalanceLimits();
    } else {
      await this.getUserTransactionsHistory();
      await this.loadAddresses();
      await this.loadCurrencies();
    }
    await this.loadBalance();
    this.setGeneralLoader(false);
  },



  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('userBalanceTransactions', {
      getUserTransactionsHistory: 'getTransactionsHistory',
    }),
    ...mapActions('userBalanceBalances', {
      loadBalance: 'loadData',
    }),
    ...mapActions('userBalanceTransactionsOld', {
      getTransactionsHistoryAction: 'getTransactionsHistory',
    }),
    ...mapActions('userBalanceAddresses', {
      loadAddresses: 'loadData',
    }),
    ...mapActions('userBalanceAddressesOld', {
      loadAddressesOld: 'loadData',
    }),
    ...mapActions('userBalanceLimits', {
      loadBalanceLimits: 'loadData',
    }),
    ...mapActions('paymentsAndCurrenciesPayments', {
      // loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),
  },
};
