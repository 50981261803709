//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Pagination from '~/components/Pagination.vue';
import User from '~/components/User.vue';
import StatusModal from '~/components/modals/StatusModalOld.vue';
import RequisitesIcon from '~/components/RequisitesIcon.vue';
import TransactionScheme from '~/components/modals/TransactionScheme.vue';
import GetReport from '~/components/GetReport.vue';

import constant from '~/const';
import Extra from './TransactionsHistoryExtra.vue';
import TransactionsHistoryFilters from './TransactionsHistoryFilters.vue';
import tableConfig from './transactionsHistoryTableConfig';

export default {
  components: {
    TransactionsHistoryFilters,
    Pagination,
    Extra,
    User,
    StatusModal,
    RequisitesIcon,
    TransactionScheme,
    GetReport,
  },

  data() {
    return {
      loader: false,
      filtersApplied: false,
      statusModal: {
        isOpen: false,
        operationId: 0,
        operationType: 0,
        kyt: {},
      },
      showTransactionScheme: false,
      tableConfig,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userBalanceTransactionsOld', ['page', 'pages', 'transactionsHistory', 'sort']),
    ...mapGetters('userBalanceTransactionsOld', ['requestData']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    statusModalData() {
      const { transactionsHistory } = this;
      const { operationId, operationType } = this.statusModal;
      return transactionsHistory?.find((e) => (e.id === operationId && e.operationType === operationType));
    },

    reportOperations: () => constant.reports.REPORT_TYPE.OPERATIONS,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadCryptoCurrencies(),
      this.loadCryptoTokens(),
      // this.getTransactionsHistory(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'confirmAction',
    ]),
    ...mapActions('userBalanceTransactionsOld', {
      getTransactionsHistoryAction: 'getTransactionsHistory',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      reloadSingleTransaction: 'reloadSingleTransaction',
      adminProcessingTransaction: 'adminProcessingTransaction',
      adminConfirmTransaction: 'adminConfirmTransaction',
      adminCompleteTransaction: 'adminCompleteTransaction',
      adminCancelTransaction: 'adminCancelTransaction',
      adminDeclineTransaction: 'adminDeclineTransaction',
      adminNeedContactTransaction: 'adminNeedContactTransaction',
      adminCheckTransaction: 'adminCheckTransaction',
      adminCancelCompletedDeposit: 'adminCancelCompletedDeposit',
      adminApproveKytTransaction: 'adminApproveKyt',
      adminRefuseKytTransaction: 'adminRefuseKyt',
      getOperation: 'getOperation',
    }),
    ...mapActions('paymentsAndCurrenciesPayments', {
      loadCryptoCurrencies: 'loadCryptoCurrencies',
      loadCryptoTokens: 'loadCryptoTokens',
    }),

    transferStatus(data) {
      this.statusModal.kytStatus = data;
    },

    async getTransactionsHistory(data = {}) {
      this.loader = true;
      this.setGeneralProgress(true);
      await this.getTransactionsHistoryAction(data);
      this.loader = false;
      this.setGeneralProgress(false);
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
      }
      this.getTransactionsHistory();
      this.filtersApplied = true;
    },

    applySort(data) {
      this.setSortAction(data);
      this.getTransactionsHistory();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.getTransactionsHistory(extendedRequestData);
    },

    isStatusCanBeChanged(row) {
      const {
        AWAITING_CONFIRMATION,
        PROCESSING,
        COMPLETED,
        // CANCELLED,
        // DECLINED,
        ADMIN_CHECK,
        IN_QUEUE,
        AWAITING_MANUAL_CHECK,
        AWAITING_USER_CONFIRMATION,
        ADMIN_PROCESSING,
        NEED_CONTACT_SUPPORT,
      } = constant.operations.TRANSACTION_STATUS;

      const { TRANSACTION, TRANSACTION_INTERNAL } = constant.operations.OPERATION_TYPE;

      const { IN } = constant.operations.OPERATION_SIDE;

      const canBeChangedStatus = [
        AWAITING_CONFIRMATION,
        PROCESSING,
        ADMIN_CHECK,
        IN_QUEUE,
        AWAITING_MANUAL_CHECK,
        AWAITING_USER_CONFIRMATION,
        ADMIN_PROCESSING,
        NEED_CONTACT_SUPPORT,
      ];

      const canBeChangedType = [TRANSACTION, TRANSACTION_INTERNAL];

      return (
        (canBeChangedStatus.includes(row.transactionStatus)
        && canBeChangedType.includes(row.operationType))
        || (canBeChangedType.includes(row.operationType) && row.transactionStatus === COMPLETED && row.side === IN)
      );
    },

    async openStatusModal(id, operationType) {
      this.statusModal.operationId = id;
      this.statusModal.operationType = operationType;

      const op = await this.getOperation({ id, operationType });
      this.statusModal.kyt = op.transaction.cryptoTransaction;
      const { transactionStatus } = this.statusModalData;
      if (transactionStatus === constant.operations.TRANSACTION_STATUS.ADMIN_PROCESSING) {
        this.confirmAction({
          title: 'Attention!',
          text: 'This transaction is in the «Admin processing» status',
          callback: () => {
            this.statusModal.isOpen = true;
          },
        });
      } else {
        this.statusModal.isOpen = true;
      }
    },

    async processing(data) {
      this.setGeneralProgress(true);
      await this.adminProcessingTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in processing');
    },

    async confirm(data) {
      this.setGeneralProgress(true);
      await this.adminConfirmTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction confirmed');
    },

    async complete(data) {
      this.setGeneralProgress(true);
      await this.adminCompleteTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction completed');
    },

    async approveKyt(data) {
      this.setGeneralProgress(true);
      await this.adminApproveKytTransaction(data);
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction approved');
    },

    async refuseKyt(data) {
      this.setGeneralProgress(true);
      await this.adminRefuseKytTransaction(data);
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction refused');
    },

    async cancel(data) {
      this.setGeneralProgress(true);
      await this.adminCancelTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction canceled');
    },

    async decline(data) {
      this.setGeneralProgress(true);
      await this.adminDeclineTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction declined');
    },

    async needContact(data) {
      this.setGeneralProgress(true);
      await this.adminNeedContactTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in Need contact support status');
    },

    async check(data) {
      this.setGeneralProgress(true);
      await this.adminCheckTransaction(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction is in Admin check status');
    },

    async cancelCompleted(data) {
      this.setGeneralProgress(true);
      await this.adminCancelCompletedDeposit(data);
      await this.getTransactionsHistoryAction({});
      await this.reloadSingleTransaction(data);
      this.setGeneralProgress(false);
      this.setSuccessNotification('Transaction canceled');
    },
  },
};
