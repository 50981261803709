var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transactions-history"},[_c('div',{staticClass:"transactions-history__filters"},[_c('TransactionsHistoryFilters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.reportOperations,"command":_vm.requestData}})],1),_c('div',{staticClass:"transactions-history__table"},[(_vm.data.length === 0 && !_vm.filtersApplied)?_c('span',{staticClass:"text-h6 d-flex justify-center",staticStyle:{"opacity":".6"}},[_vm._v(" "+_vm._s(_vm.$t('Apply filters and search to see the data'))+" ")]):_vm._e(),[(_vm.filtersApplied)?_c('Table',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"to-set":function (e) { return ((e.id) + "/" + (e.operationType) + "/" + (e.side)); },"show-extra":""},on:{"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mt-4 mb-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pb-2 pr-4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('KYT Risks:'))+" ")]),(row.kytRisks)?_c('div',{staticClass:"pb-2"},[_c('v-row',_vm._l((row.kytRisks),function(risk,type){return _c('v-col',{key:type,attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.$t(type))+": ")]),_c('p',{style:({ color: _vm.riskColor(risk) })},[_vm._v(" "+_vm._s(parseFloat(Number(risk * 100).toFixed(2)))+"% ")])])])}),1)],1):_c('div',{staticClass:" pb-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('No risks')))])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pb-2 pr-4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('KYT Risk:'))+" ")]),(row.kytRisk)?_c('div',{staticClass:" pb-2"},[_c('p',{style:({ color: _vm.riskColor(row.kytRisk) })},[_vm._v(" "+_vm._s(parseFloat(Number(row.kytRisk * 100).toFixed(2)))+"% ")])]):_c('div',{staticClass:" pb-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('No risks')))])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:" pb-2 pr-4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('KYT Status:'))+" ")]),(row.kytStatus != 0)?_c('div',{staticClass:"pb-2"},[_c('p',[_vm._v(_vm._s(_vm.$utils.getCaption(_vm.constant.transactions.KYT_STATUS_LIST, row.kytStatus)))])]):_c('div',{staticClass:" pb-2"},[_c('p',[_vm._v(_vm._s(_vm.$t('Clear')))])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:" pb-2 pr-4 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('Receiver:'))+" ")]),(row.receiver)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(row.receiver)+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" - ")])])])]}},{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"transactions-history__id"},[_c('div',[_vm._v(_vm._s(row.id))]),_c('div',{staticClass:"text--secondary"},[(row.internal)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Internal'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('External'))+" ")])])])]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.$utils.getUnixDate(row.created))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getUnixTime(row.created)))])])]}},{key:"user",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":Number(row.userId),"username":row.userName}})]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.constant.traders.NEW_TRANSACTIONS_IOTA[row.type])))])]}},{key:"statusHeader",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Status'))+" ")]},proxy:true},{key:"status",fn:function(ref){
var row = ref.row;
return [(_vm.isStatusCanBeChanged(row))?_c('span',{staticClass:"text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.openStatusModal(row.id, row.operationType)}}},[_vm._v(" "+_vm._s(_vm.$t(("const." + (_vm.constant.traders.TRANSACTION_STATUS_LIST[row.status].caption))))+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.$t(("const." + (_vm.constant.traders.TRANSACTION_STATUS_LIST[row.status].caption))))+" ")])]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.toCurrencyFormat(row.amount, row.currency))+" "+_vm._s(row.currency)+" ")])]}},{key:"network",fn:function(ref){
var row = ref.row;
return [(row.network)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(row.network)+" ")]):_c('span',{staticClass:"text-no-wrap"},[_vm._v(" - ")])]}},{key:"fee",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.fee)+" ")]}}],null,false,381487844)}):_vm._e(),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages},on:{"input":_vm.setPage}})],1)]],2),_c('StatusModal',{attrs:{"data":_vm.statusModalData},on:{"confirm":_vm.confirm,"complete":_vm.complete,"cancel":_vm.cancel},model:{value:(_vm.statusModal.isOpen),callback:function ($$v) {_vm.$set(_vm.statusModal, "isOpen", $$v)},expression:"statusModal.isOpen"}}),_c('TransactionScheme',{model:{value:(_vm.showTransactionScheme),callback:function ($$v) {_vm.showTransactionScheme=$$v},expression:"showTransactionScheme"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }