export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'slot',
      name: 'id',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Side',
    },
    cell: {
      type: 'slot',
      name: 'side',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 10,
    header: {
      type: 'slot',
      name: 'statusHeader',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Amount',
      sort: 'amount',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Transaction method',
    },
    cell: {
      type: 'text',
      value: (e) => e.transactionMethodName,
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Network',
    },
    cell: {
      type: 'slot',
      name: 'network',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'slot',
      name: 'fee',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'MFA',
    },
    cell: {
      type: 'slot',
      name: 'mfa',
    },
  },
  {
    width: 4,
    header: {
      type: 'text',
      caption: 'API',
    },
    cell: {
      type: 'slot',
      name: 'api',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'Req',
    },
    cell: {
      type: 'slot',
      name: 'requisites',
    },
  },
];
