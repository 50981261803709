//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    kyt: { type: [Object, Array], default: () => ({}) },
  },

  data() {
    return {
      comment: '',
      details: '',
      address: '',
    };
  },

  mounted() {
    this.setComment();
  },

  computed: {
    ...mapGetters('login', ['getCurrentAdmin']),
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    detailsInput() {
      return {
        show: true,
      };
    },

    cashParsedData() {
      const array = this.$props.data?.data.split(',\n') || [];
      if (array?.length) {
        return {
          city: array[0],
          officeAddress: array[1],
          dateAndTime: array[2],
          fullName: array[3],
          documentInfo: array[4],
        };
      }
      return [];
    },

    isShowReturningAddress() {
      return this.$props.kyt?.kytStatus > 0 && this.$props.data.transactionStatus === constant.operations.TRANSACTION_STATUS.ADMIN_PROCESSING;
    },

    commentInput() {
      const { transactionStatus, side } = this.data;
      const {
        PROCESSING,
        ADMIN_PROCESSING,
        // AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      return {
        show: (
          ([PROCESSING, ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
          || ([
            PROCESSING,
            ADMIN_PROCESSING,
            // AWAITING_MANUAL_CHECK,
            ADMIN_CHECK,
          ].includes(transactionStatus) && side === IN)
        ),
      };
    },

    processingButton() {
      const { transactionStatus } = this.data;
      const {
        AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
        NEED_CONTACT_SUPPORT,
        IN_QUEUE,
      } = constant.operations.TRANSACTION_STATUS;
      const { details } = this;
      return {
        show: [
          AWAITING_MANUAL_CHECK,
          ADMIN_CHECK,
          NEED_CONTACT_SUPPORT,
          IN_QUEUE,
        ].includes(transactionStatus),
        disabled: !details,
        title: this.$t('Start processing'),
        className: 'primary--text',
        callback: {
          name: 'processing',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    confirmButton() {
      const { transactionStatus, side } = this.data;
      const {
        ADMIN_PROCESSING,
        // AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { details } = this;
      const show = (
        (([ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          ADMIN_PROCESSING,
          // AWAITING_MANUAL_CHECK,
          ADMIN_CHECK,
        ].includes(transactionStatus) && side === IN)) && (this.$props?.kyt?.kytStatus === undefined || this.$props?.kyt?.kytStatus === 0)
      );
      return {
        show,
        disabled: !details,
        title: this.$t('Send now!'),
        className: 'success--text',
        callback: {
          name: 'confirm',
          params: {
            ...this.data,
            details: this.details,
            transactionId: this.data.id,
          },
        },
      };
    },

    approveKYTButton() {
      const { transactionStatus, side } = this.data;
      const {
        ADMIN_PROCESSING,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { details } = this;
      const show = (
        (([ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          ADMIN_PROCESSING,
        ].includes(transactionStatus) && side === IN)) && this.$props?.kyt?.kytStatus
      );
      return {
        show,
        disabled: !details,
        title: this.$t('Send now!'),
        className: 'success--text',
        callback: {
          name: 'approve',
          params: {
            ...this.data,
            details: this.details,
            transactionId: this.data.id,
          },
        },
      };
    },

    completeButton() {
      const { transactionStatus, side } = this.data;
      const {
        PROCESSING,
        ADMIN_PROCESSING,
        // AWAITING_MANUAL_CHECK,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { comment, details } = this;
      const show = (
        ([PROCESSING, ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          PROCESSING,
          ADMIN_PROCESSING,
          // AWAITING_MANUAL_CHECK,
          ADMIN_CHECK,
        ].includes(transactionStatus) && side === IN)
      );
      return {
        show,
        disabled: !comment || !details,
        title: this.$t('Mark as Done'),
        className: 'primary--text',
        callback: {
          name: 'complete',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
            comment: this.comment,
          },
        },
      };
    },

    cancelButton() {
      const { transactionStatus } = this.data;
      const {
        // AWAITING_MANUAL_CHECK,
        AWAITING_USER_CONFIRMATION,
      } = constant.operations.TRANSACTION_STATUS;
      const { details } = this;
      return {
        show: [
          // AWAITING_MANUAL_CHECK,
          AWAITING_USER_CONFIRMATION,
        ].includes(transactionStatus),
        disabled: !details,
        title: this.$t('Cancel'),
        className: 'error--text',
        callback: {
          name: 'cancel',
          params: {
            ...this.data,
            comment: this.details,
            transactionId: this.data.id,
          },
        },
      };
    },

    declineButton() {
      const { transactionStatus, side } = this.data;
      const {
        PROCESSING,
        ADMIN_PROCESSING,
        ADMIN_CHECK,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { comment, details } = this;
      const show = (
        (([PROCESSING, ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          PROCESSING,
          ADMIN_PROCESSING,
          ADMIN_CHECK,
        ].includes(transactionStatus) && side === IN)) && (this.$props?.kyt?.kytStatus === undefined || this.$props?.kyt?.kytStatus === 0)
      );
      return {
        show,
        disabled: !comment || !details,
        title: this.$t('Decline'),
        className: 'error--text',
        callback: {
          name: 'decline',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
            comment: this.comment,
          },
        },
      };
    },

    refuseKYTButton() {
      const { transactionStatus, side } = this.data;
      const {
        ADMIN_PROCESSING,
      } = constant.operations.TRANSACTION_STATUS;
      const { IN, OUT } = constant.operations.OPERATION_SIDE;
      const { comment, details } = this;
      const show = (
        (([ADMIN_PROCESSING].includes(transactionStatus) && side === OUT)
        || ([
          ADMIN_PROCESSING,
        ].includes(transactionStatus) && side === IN)) && this.$props?.kyt?.kytStatus
      );
      return {
        show,
        disabled: !comment || !details,
        title: this.$t('Decline'),
        className: 'error--text',
        callback: {
          name: 'refuse',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
            address: this.address,
            comment: this.comment,
          },
        },
      };
    },

    needContactButton() {
      const { transactionStatus } = this.data;
      const { ADMIN_PROCESSING } = constant.operations.TRANSACTION_STATUS;
      const { details } = this;
      return {
        show: [ADMIN_PROCESSING].includes(transactionStatus),
        disabled: !details,
        title: this.$t('Need contact'),
        className: 'primary--text',
        callback: {
          name: 'needContact',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    checkButton() {
      const { transactionStatus } = this.data;
      const { PROCESSING, AWAITING_CONFIRMATION } = constant.operations.TRANSACTION_STATUS;
      return {
        show: [PROCESSING, AWAITING_CONFIRMATION].includes(transactionStatus),
        disabled: false,
        title: this.$t('Admin check'),
        className: 'primary--text',
        callback: {
          name: 'check',
          params: {
            ...this.data,
            transactionId: this.data.id,
          },
        },
      };
    },

    cancelCompletedButton() {
      const { transactionStatus, side } = this.data;
      const { COMPLETED } = constant.operations.TRANSACTION_STATUS;
      const { IN } = constant.operations.OPERATION_SIDE;
      const { details } = this;
      return {
        show: [COMPLETED].includes(transactionStatus) && side === IN,
        disabled: !details,
        title: this.$t('Cancel completed'),
        className: 'error--text',
        callback: {
          name: 'cancelCompleted',
          params: {
            ...this.data,
            transactionId: this.data.id,
            details: this.details,
          },
        },
      };
    },

    buttonList() {
      return [
        this.processingButton,
        this.confirmButton,
        this.completeButton,
        this.cancelButton,
        this.declineButton,
        this.needContactButton,
        this.checkButton,
        this.cancelCompletedButton,
        this.approveKYTButton,
        this.refuseKYTButton,
      ].filter((e) => e.show);
    },
  },

  watch: {
    value(value) {
      if (!value) {
        this.comment = '';
        this.details = '';
      }
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction']),

    clickHandler({ name, params }) {
      this.confirmAction({
        text: 'Are you sure?',
        callback: () => {
          this.$emit(name, params);
          this.$emit('input', false);
        },
      });
    },

    setComment() {
      this.details = this.$t('InternalAdminCommentPlaceholder', { email: this.getCurrentAdmin.email });
    },
  },
};
