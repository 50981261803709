//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        search: undefined,
        amountFrom: undefined,
        amountTo: undefined,
        isUsed: undefined,
        currency: [],
        createdFrom: undefined,
        createdTo: undefined,
      },
    };
  },

  computed: {
    ...mapState('userBalanceAddresses', {
      filterLists: 'filterLists',
      stateFilters: 'filters',
    }),
    ...mapState('tradersCurrencies', { currencies: 'data' }),

    statusList() {
      return [
        { title: 'Active', value: true },
        { title: 'Inactive', value: false },
      ];
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userBalanceAddresses', ['setFilters']),
  },
};
