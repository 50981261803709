//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';

import constant from '~/const';

export default {
  components: {
    Select,
    DateRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        createdFrom: '',
        createdTo: '',
        type: [],
        currency: [],
        status: [],
      },
      isFirstSearch: true,
      constant,
    };
  },

  computed: {
    ...mapState('userBalanceTransactions', ['filterLists']),
    ...mapState('currencies', ['currencies']),
    ...mapState('paymentsAndCurrenciesPayments', [
      'cryptoCurrencies',
      'cryptoTokens',
    ]),

    cryptoTokensFiltered() {
      const { cryptoTokens } = this;
      const { cryptoCurrencyIdList } = this.filters;
      return cryptoTokens.filter((e) => cryptoCurrencyIdList.includes(e.cryptoCurrencyId));
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('userBalanceTransactions', ['setFilters', 'setSort']),

    async firstSearch() {
      const { filters } = this;

      this.isFirstSearch = false;
      await this.setFilters(filters);
      this.$emit('update');
    },
  },
};
