//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import GetReport from '~/components/GetReport.vue';
import constant from '~/const';
import Filters from './UserBalanceBalancesFilters.vue';
import UserBalanceBalancesChart from './UserBalanceBalancesChart.vue';
import AddBalanceModal from './UserBalanceBalancesAddBalanceModal.vue';
import WithdrawalBalanceModal from './UserBalanceBalancesWithdrawalBalanceModal.vue';

import tableConfig from './tableConfig';
import p2payTableConfig from './p2payTableConfig';

export default {
  components: {
    Filters,
    GetReport,
    UserBalanceBalancesChart,
    AddBalanceModal,
    WithdrawalBalanceModal,
  },

  data() {
    return {
      tableConfig,
      p2payTableConfig,
      constant,
      selection: [],
      searchString: '',
      searchCurrencies: [],
      loader: false,
      addBalanceState: false,
      withdrawalBalanceState: false,
    };
  },

  computed: {
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('userBalanceBalances', ['requestData']),
    ...mapGetters('currencies', ['getCurrencyById']),

    ...mapState('common', ['generalLoader']),
    ...mapState('currencies', { currenciesOld: 'currencies' }),
    ...mapState('userBalanceBalances', ['page', 'pages', 'data', 'additionalData', 'sort', 'filters']),
    ...mapState('tradersCurrencies', { currencies: 'data' }),

    getCurrencies() {
      return this.currencies.filter((e) => e.type === 0);
    },

    getBalanceTypesByVersion() {
      switch (this.$utils.getAppVersion()) {
        case 1:
          return ['limit', 'profit', 'trust', 'hold', 'debt'];
        case 2:
        case 3:
          return ['trust', 'hold'];
        default:
          return ['limit', 'profit', 'trust', 'hold', 'debt'];
      }
    },

    filteredData() {
      // Деструктурируем необходимые свойства из текущего контекста (this)
      const { searchString, data } = this;
      // Собираем все уникальные валюты
      if (this.$utils.getAppType() === 'p2pay') {
        const currencies = new Set();
        data.forEach((entry) => {
          this.getBalanceTypesByVersion.forEach((type) => {
            entry[type].forEach((item) => {
              currencies.add(item.currency);
            });
          });
        });
        // Формируем результирующий массив с необходимыми столбцами
        const result = Array.from(currencies).map((currency) => {
          const row = { currency };
          this.getBalanceTypesByVersion.forEach((type) => {
            const found = data.flatMap((entry) => entry[type]).find((item) => item.currency === currency);
            row[type] = found ? found.value : null; // Используем null для пустых значений
          });
          return row;
        });
        return result;
      }

      // Если тип приложения не 'p2pay', применяем фильтрацию к данным иначе
      return data.filter((e) => {
        const text = e.currencyTitle.toLowerCase();
        const query = searchString.toLowerCase();
        return text.includes(query);
      }).filter((e) => {
        return !this.searchCurrencies.length || this.searchCurrencies.includes(e.currencyTitle);
      });
    },

    routerId() {
      return this.$route.params.id;
    },
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'setSuccessNotification', 'setGeneralProgress']),
    ...mapActions('userBalanceBalances', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    filterByCurrency() {
      if (this.$utils.getAppType() !== 'p2pay') {
        return;
      }
      this.setGeneralLoader(true);
      this.loadDataAction({ currency: this.searchCurrencies });
      this.setGeneralLoader(false);
    },

    getCurrency(id) {
      const currency = this.getCurrencyById(id);
      if (currency) {
        return currency.title;
      }
      return '';
    },

    getAvailableBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.availableFunds;
        });
        return sum;
      }
      return 0;
    },

    getCumulativeAvailableBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.cumulativeAvailableFunds;
        });
        return sum.toFixed(2);
      }
      return 0;
    },

    getFrozenBalance(row) {
      const { balances } = row;
      if (balances && balances.length) {
        let sum = 0;
        balances.forEach((e) => {
          sum += e.frozenFunds;
        });
        return sum;
      }
      return 0;
    },

    addBalanceToUser() {
      this.addBalanceState = !this.addBalanceState;
    },

    withdrawalBalanceFromUser() {
      this.withdrawalBalanceState = !this.withdrawalBalanceState;
    },

    closeModalAddBalance() {
      this.addBalanceState = false;
    },

    closeModalWithdrawalBalance() {
      this.withdrawalBalanceState = false;
    },
  },

  watch: {
    addBalanceState: {
      handler(value) {
        if (!value) {
          this.$refs.addBalanceModal.clearErrors();
        }
      },
    },
    withdrawalBalanceState: {
      handler(value) {
        if (!value) {
          this.$refs.withdrawalBalanceModal.clearErrors();
        }
      },
    },
  },
};
